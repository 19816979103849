<template>
  <div class="container-wrap">
    <v-container>
      <v-row class="rowe">
        <v-col xl="4" lg="4" md="4" sm="12" cols="12">
          <h2 class="intro-text">
            Business Solutions <br />
            For All Enterprises
          </h2>
          <p class="description">
            Tailored solutions for your needs | Delivering value to our
            clients | Fostering long-term value
          </p>
          <br/>
          <router-link to="/business-strategy"  class="cta-btn">SERVICES</router-link>
        </v-col>

        <v-col class="col1" xl="8" lg="8" md="8" sm="12" cols="12">
          <img width="100%" src="@/assets/1 landing section.png" alt="" />
          <!-- <img src="@/assets/growth-analysis.gif" class="HeroComponent-img1" alt=""> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HeroComponent",
};
</script>
<!-- 
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); -->

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container-wrap {
  background: transparent linear-gradient(180deg, #717171 0%, #000000 100%) 0% 0% no-repeat padding-box;
  background: linear-gradient(#717171, #000000);
  position: relative;
  aspect-ratio: 16/8.5;
  display: flex;
  justify-content: center;
  align-items: center;

}

.intro-text {
  text-align: left;
  // font: normal normal medium 79px/105px Poppins;
  font-weight: 500;
  font-size: 2.8vw;
  color: #ffffff;
}

p.description {
  margin-top: 30px;
  // font: normal normal normal 20px/32px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.rowe {
  padding-left: 3%;
  align-items: center;
  a{
    text-decoration: none;
  }
}

/* .HeroComponent-img1{
  position: absolute;
  transform: rotate(360deg);
  left: 800px;
  top: 0;
} */

.btn-first {
  margin-top: 30px;
  border-radius: 15px;
  padding: 30px 50px 30px 50px !important;
  
}

.hero-img-display {
  position: relative;

  .HeroComponent-img1 {
    position: absolute;
    transform: rotate(360deg);
    left: 0px;
    top: 0;
    width: 100%;
  }

  .Her0-component-img {
    // position: absolute;
    // left: 0px;
    // top: 0;
    width: 100%;
  }
}

.col1{
  padding-top: 50px;
}

@media (max-width: 961px) {
  .rowe .intro-text{
  font: normal 30px Poppins;
    line-height: 36px;
    // padding-top: 30%;
  }

  .rowe .description{
    font: normal 16px Poppins;
  }
  .container-wrap {
    padding-top: 10%;
  }
}

@media (min-width: 1029px) and (max-width: 1263px){
  .intro-text{
    font-size: 24px;
    width: 100%;
  }
}

// @media (max-width: 425px) {
//   .rowe .intro-text{
//   font: normal 30px Poppins;
//     line-height: 36px;
//     padding-top: 30px;
//   }

//   .rowe .description{
//     font: normal 16px Poppins;
//   }
// } 
</style>