<template>
  <footer>
    <div class="footer-container">
      <div class="left">
        <a
          href="https://www.linkedin.com/in/kasthwal/"
          target="_blank"
          class="footer-social-links"
        >
          <span
            >Follow us on: &nbsp;
            <i class="fa fa-linkedin" aria-hidden="true"></i></span
        ></a>
      </div>
      <div class="middle">
        {{ middleText }}
        <a class="luxeveda-a" href="https://luxeveda.com/" target="_blank"
          ><span class="luxeveda">Luxeveda</span></a
        >
      </div>
      <div class="right">
        <router-link to="/Terms" class="Footer-terms">
          {{ rightText }}</router-link
        >
        <span class="left-text-span">
          <router-link to="/Privacy" class="Footer-terms">{{
            leftText
          }}</router-link></span
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      imageSrc: "path/to/image.jpg",
      middleText: `Copyright © ${new Date().getFullYear()} Narmada. All Rights Reserved | Designed by`,
      rightText: "Terms of Use",
      leftText: "Privacy Policy",
    };
  },
};

</script>

<style lang="scss" scoped>
.footer-container {
  display: flex;
  background: #270e00 0% 0% no-repeat padding-box;
  padding: 40px;
}
.footer-head {
  font-weight: 500;
}

.fa {
  font-size: 16px;
}

.footer-social-links {
  text-decoration: none;
  color: #fff;
}
.left {
  flex: 1;
  text-align: left;
  color: #fff;
}

.middle {
  flex: 1;
  text-align: center;
  color: #fff;
}

.luxeveda-a {
  text-decoration: none;
}
.luxeveda {
  color: #fff;
}

.right {
  flex: 1;
  text-align: right;
  color: #fff;
}

.left-text-span {
  margin-left: 40px;
}

.fa-linkedin {
  font-size: 20px;
  text-decoration: none;
}
.Footer-terms {
  color: #fff;
  text-decoration: none;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .left {
    font-size: 14px;
  }
  .middle {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .right {
    margin-top: 10px;
    font-size: 10px;
  }
  .fa-linkedin {
    font-size: 16px;
  }
}
</style>
